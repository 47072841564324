import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import vREFYD_WHITE from '../../../Assets/images/vREfyd_logo.png';
import SUCCESSTICK from '../../../Assets/images/success-tick.png';

const Flyer4 = ({ selectedproperty, coverImage }) => {
  return (
    <Box
      id="flyer1"
      sx={{
        width: '480px',
        height: '601px',
        margin: '10px',
        backgroundImage: `url(${coverImage})`, 
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
        backgroundRepeat: 'no-repeat',
        position: 'relative', 
        overflow: 'hidden',
      }}
    >
        <Box sx={{
            top:0,
            width:'100%',
            background:'#0000008b',
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            padding:'13px 10px'

        }}>
                <Box sx={{display:'flex',flexDirection:'row',marginLeft:'15px'}}>
                    <Avatar
            src={selectedproperty?.profile_picture}
            sx={{
              width: '35px',
              height: '35px',
              objectFit: 'cover',
              zIndex: 0,
            }}
          />
          <Box
            sx={{
              marginLeft: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <Typography sx={{ color: '#FEFEFE', fontSize: '10px' }}>
              Listed by
            </Typography>
            <Typography
              sx={{
                color: '#FFFFFF',
                fontSize: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {selectedproperty?.created_by}
              {selectedproperty?.is_verified && (
                <img
                  src={SUCCESSTICK}
                  alt="profile"
                  className="agent-verified-icon"
                  style={{ zIndex: 1 }}
                />
              )}
            </Typography>
          </Box>
                    </Box>
                    <Typography
        sx={{
          display: 'flex',
          marginRight: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#FEFEFE',
          fontSize: '12px',
        }}
      >
        Made with the Magic of
        <img src={vREFYD_WHITE} width={60} alt="vREfyd" />
      </Typography>

        </Box>
        <Box sx={{
            position:'absolute',
            left:'15px',
            bottom:0,
            background:'#0000008b',
            width:'60%',
            padding:'10px',
            borderRadius:'8px 8px 0 0'

        }}>
            <Typography sx={{fontSize:'22px',color:'#ffffff',my:'10px'}}>Where Elegance Meets Home</Typography>
            <Typography sx={{fontSize:'15px',color:'#ffffff',mb:'10px'}} >Sun-drenched living areas with inviting ambiance</Typography>
            <Typography sx={{fontSize:'14px',color:'#ffffff',}} >{selectedproperty?.locality}</Typography>
        </Box>
        
    </Box>
  );
};

export default Flyer4;
