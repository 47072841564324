export const SESSION_EXPIRY = 'Session Expired!';
export const INTERNAL_ERROR = 'Internal server error!';
export const SERVER_ERROR = "Server can't be reached!";
export const NO_ACCESS_TO_THE_PAGE = 'Forbidden!';

export const APP_VERSION = 'app31625';
export const GOOGLE_MAPS_API_KEY = 'AIzaSyBLkczqTV5kn8HrJcwpJF-IsSBs9cwauxk';

export const SOCKETURL = `wss://dev-aira-apis.vrefyd.com/ws/chat/?token=`;
export const SOCKETCHATURL = `wss://dev-aira-apis.vrefyd.com/ws/user/chat/?token=`;
// export const SOCKETURL = `wss://aira-apis.vrefyd.com/ws/chat/?token=`;
// export const SOCKETCHATURL = `wss://aira-apis.vrefyd.com/ws/user/chat/?token=`;

export const LOCAL_STORAGE_DATA = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  USER: 'userDetails',
  APP_VERSION: 'app-version',
};

export const ALERT_TYPES = {
  info: 'info',
  error: 'error',
  success: 'success',
  warning: 'warning',
};

export const RENTVALUE = 'Rent';
export const SALEVALUE = 'Purchase';
export const RENT_VALUE_ADD_PROPERTY = 'For Rent';
export const SALEVALUEADDPROPERTY = 'For Sale';

export const minSaleValue = 1000000;
export const maxSaleValue = 1000000000;
export const minRentValue = 10000;
export const maxRentValue = 1000000;

export const MumbaiNames = [
  "Mumbai",
  "Bombay",
  "Navi Mumbai",
  "Thane",
  "Andheri",
];

export const HyderabadNames = [
  "Hyderabad",
  "Cyberabad",
  "Secunderabad",
  "Telangana"
];


export const ChennaiNames = [
  "Chennai",
  "Madras",
  "Tamil Nadu"
];


export const DelhiNames = [
  "Delhi",
  "New Delhi",
  "Gurgaon",
  "Gurugram",
  "Noida",
  "Greater Noida",
  "Ghaziabad",
  "Faridabad",
  "Dwarka",
  "South Delhi",
  "North Delhi",
  "East Delhi",
  "West Delhi",
];


export const BangaloreNames = [
  "Bangalore",
  "Bengaluru",
  "Bangaluru",
  "Bangalore Urban",
  "Bangalore Rural",
];



// To Restrict places to api to specific areas 

export const BangloreLocationBias = {
  center: { lat: 12.9716, lng: 77.608476 }, //  center coordinates
  radius: 25000, // 25 km 
}

export const DelhiLocationBias = {
  center: { lat: 28.583552, lng: 77.235670 ,  },
  radius: 25000, // 25 km 
}

export const ChennaiLocationBias = {
  center: { lat: 13.061645, lng: 80.19988 ,  }, 
  radius: 25000, // 25 km 
}

export const HydLocationBias = {
  center: { lat: 17.431935, lng: 78.438241 ,  }, 
  radius: 25000, // 25 km 
}

export const MumbaiLocationBias = {
  center: { lat: 19.101300, lng: 72.913257 ,  }, 
  radius: 25000, // 25 km 
}
export const excludeIds =  ['df0d13ca-032c-11f0-a5eb-7f585865a157','d1c0441a-0496-11f0-a5eb-a31ee2becbce','f0d13ca-032c-11f0-a5eb-7f585865a157','e35f628e-032c-11f0-a5eb-ab43d35c8030','9f71ae00-02f6-11f0-a5eb-af3b159dba6f','a0424d1c-02f6-11f0-a5eb-df6ef453d389','5c61d02a-02f3-11f0-a5eb-9b495d41f428','5b6c8bb0-02f3-11f0-a5eb-c721a0e5bd17','951aa0dc-02f2-11f0-a5eb-9fd312f9839e','e461d9ac-02f3-11f0-a5eb-931ec2425bce','dddb21cc-02f0-11f0-a5eb-7f48c6350069','9f422694-02f6-11f0-a5eb-1348c85b6ac8']
