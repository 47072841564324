import { Avatar, Box, Divider, Typography } from '@mui/material';
import React from 'react';
import vREFYD_WHITE from '../../../Assets/images/vREfyd_logo.png';
import SUCCESSTICK from '../../../Assets/images/success-tick.png';
import { numDifferentiation } from '../../../Utils/helperUtils';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';

const Flyer6 = ({ selectedproperty, coverImage }) => {
  return (
    <>
      <Box
        id="flyer1"
        sx={{
          width: '480px',
          height: '601px',
          margin: '10px',
          backgroundColor: 'white',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            height: '20%',
            padding: '10px',
            background:
              'linear-gradient(180deg, rgba(10,10,10,1) 0%, rgba(142,142,142,0) 100%)',
          }}
          zIndex={1}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              zIndex: 0,
            }}
          >
            <Avatar
              src={selectedproperty?.profile_picture}
              sx={{
                width: '40px',
                height: '40px',
                objectFit: 'cover',
                zIndex: 0,
              }}
            />
            <Box
              sx={{
                marginLeft: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
              }}
            >
              <Typography sx={{ color: '#FEFEFE', fontSize: '10px' }}>
                Listed by
              </Typography>
              <Typography
                sx={{
                  color: '#FFFFFF',
                  fontSize: '14px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {selectedproperty?.created_by}
                {selectedproperty?.is_verified && (
                  <img
                    src={SUCCESSTICK}
                    alt="profile"
                    className="agent-verified-icon"
                    style={{ zIndex: 1 }}
                  />
                )}
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{
              display: 'flex',
              marginRight: '10px',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#FEFEFE',
              fontSize: '12px',
            }}
          >
            Made with the Magic of
            <img src={vREFYD_WHITE} width={55} alt="vREfyd" />
          </Typography>
        </Box>

        {/* Cover Image */}
        <Box
          sx={{
            width: '100%',
            height: '75%',
            backgroundImage: `url(${coverImage})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
          }}
        />

        {/* Place Name Box (Below Image, Centered) */}
        <Box
          id="placeName"
          sx={{
            backgroundColor: '#600DB4',
            height: '35px',
            width: '250px',
            borderRadius: '12px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'white',
            textAlign: 'center',
            position: 'absolute',
            top: '72%',
            left: '50%',
            transform: 'translate(-50%, 0%)',
            zIndex: 2,
            gap: '5px',
            padding: '10px',
          }}
        >
          <FmdGoodOutlinedIcon sx={{ width: '16px' }} />
          <Typography>{selectedproperty?.locality}</Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '20px',
            paddingBottom: '5px',
            marginTop: '5px',
          }}
        >
          <Box
            sx={{
              width: '40%',
              padding: '2px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                textShadow: '0px 20.91px 188.21px rgba(0, 0, 0, 0.25)',
                fontFamily: 'Inter !Important',
                color: '#474747',
                fontSize: '14px',
                textAlign: 'left',
              }}
            >
              {selectedproperty?.property_sale_type === 'Purchase'
                ? 'For Sale'
                : 'For Rent'}{' '}
            </Typography>
            <Typography
              sx={{
                fontWeight: 700,
                textShadow: '0px 20.91px 188.21px rgba(0, 0, 0, 0.25)',
                fontFamily: 'Inter !Important',
                letterSpacing: '-2px',

                color: '#474747',
                fontSize: '24px',
                textAlign: 'center',
              }}
            >
              {selectedproperty?.sale_price
                ? numDifferentiation(selectedproperty?.sale_price)
                : numDifferentiation(selectedproperty?.rent_amount)}
            </Typography>
            {selectedproperty?.property_sale_type === 'Rent' && (
              <Typography
                sx={{
                  color: '#474747',
                  textShadow: '0px 20.91px 188.21px rgba(0, 0, 0, 0.25)',
                  fontFamily: 'Inter !Important',
                  fontWeight: 700,
                  textAlign: 'right',
                  fontSize: '14px',
                }}
              >
                per month
              </Typography>
            )}
          </Box>
          <Divider
            sx={{ margin: '10px', marginRight: '15px' }}
            orientation="vertical"
            variant="middle"
            flexItem
          />
          <Box sx={{ width: '70%', padding: '2px' }}>
            <Typography
              sx={{
                color: '#474747',
                textShadow: '0px 20.91px 188.21px rgba(0, 0, 0, 0.25)',
                fontFamily: 'Inter !Important',
                fontWeight: 700,
                fontSize: '16px',
              }}
            >
              {selectedproperty?.property_title}
            </Typography>
            <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-start',width:'100%',mt:1}}>
              {selectedproperty?.bedroom_count && <Typography sx={{fontSize:'12px',display:'flex',alignItems:'center',mr:1,fontWeight:600}}><BedOutlinedIcon sx={{color:'#600DB4',mr:1}} /> {selectedproperty?.bedroom_count} </Typography>}
              {selectedproperty?.bathroom_count && <Typography sx={{fontSize:'12px',display:'flex',alignItems:'center',mr:1,fontWeight:600}}><BathtubOutlinedIcon sx={{color:'#600DB4',mr:1}} /> {selectedproperty?.bathroom_count}</Typography>}
              {selectedproperty?.built_up_area && <Typography sx={{textWrap:'nowrap',fontSize:'12px',display:'flex',alignItems:'center',flexDirection:'row',mr:'5px',fontWeight:600}}><SquareFootOutlinedIcon sx={{color:'#600DB4',mr:'2px'}} /> {selectedproperty?.built_up_area} Sq. Ft.</Typography>}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Flyer6;
