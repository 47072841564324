import { Avatar, Box, Divider, Typography } from '@mui/material';
import React from 'react';
import vREFYD_WHITE from '../../../Assets/images/vREfyd_logo.png';
import SUCCESSTICK from '../../../Assets/images/success-tick.png';
import { numDifferentiation } from '../../../Utils/helperUtils';

const Flyer8 = ({ selectedproperty, coverImage }) => {
  return (
    <Box
      id="flyer1"
      sx={{
        width: '480px',
        height: '601px',
        margin: '10px',
        backgroundImage: `url(${coverImage})`, 
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
        backgroundRepeat: 'no-repeat',
        position: 'relative', 
        overflow: 'hidden',
      }}
    >

        <Box sx={{
            position:'absolute',
            bottom:0,
            width:'100%',
            padding:'30px 40px',
            background:
            'linear-gradient(0deg, rgba(10,10,10,1) 10%, rgba(132,132,132,0) 100%)',
            display:'flex',
            flexDirection:'column',
            alignItems:'flex-start',
            justifyContent:'flex-start',

        }}>
            <Typography sx={{padding:'3px 35px',margin:"10px 0",borderRadius:'100px',fontSize:'12px',border:'1px solid #fff',color:'#fff',background:'rgba(0, 0, 0, 0.25)'}}> 
            {selectedproperty?.property_sale_type === 'Purchase'
                                    ? 'For Sale'
                                    : 'For Rent'}{' '}
            </Typography>
            <Typography sx={{
                fontWeight: 700,
                textShadow: '0px 20.91px 188.21px rgba(0, 0, 0, 0.25)',
                // fontFamily: 'Inter !Important',
                lineHeight:'30px',
                color: '#ffffff',
                fontSize: '24px',
            }}>{selectedproperty?.property_title}</Typography>
            <Box sx={{
                height:'1px',
                width:'100%',
                background:'#ffffff5e',my:'10px'
            }}>

            </Box>
            <Typography sx={{color:'#fff',fontSize:'14px'}}>Start From </Typography>
            <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
            <Typography
          sx={{
            fontWeight: 700,
            textShadow: '0px 20.91px 188.21px rgba(0, 0, 0, 0.25)',
            fontFamily: 'Inter !Important',
            letterSpacing: '-2px',
            color: '#FFFFFF',
            fontSize: '24px',
          }}
        >
          {selectedproperty?.sale_price
            ? numDifferentiation(selectedproperty?.sale_price)
            : numDifferentiation(selectedproperty?.rent_amount)}
        </Typography>
        {selectedproperty?.property_sale_type === 'Rent' && <Typography
          sx={{
            color: '#ffffff',
            textShadow: '0px 20.91px 188.21px rgba(0, 0, 0, 0.25)',
            fontFamily: 'Inter !Important',
            fontWeight: 700,
            textAlign:'right',
            fontSize: '24px',
            marginLeft:'8px'
          }}
        >
          / Month
        </Typography>}
            </Box>
  
        <Box sx={{
                height:'1px',
                width:'100%',
                background:'#ffffff5e',my:'10px'
            }}></Box>
        <Box sx={{
            top:0,
            width:'100%',
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            padding:'10px 0px'

        }}>
                <Box sx={{display:'flex',flexDirection:'row'}}>
                    <Avatar
            src={selectedproperty?.profile_picture}
            sx={{
              width: '35px',
              height: '35px',
              objectFit: 'cover',
              zIndex: 0,
            }}
          />
          <Box
            sx={{
              marginLeft: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <Typography sx={{ color: '#FEFEFE', fontSize: '10px' }}>
              Listed by
            </Typography>
            <Typography
              sx={{
                color: '#FFFFFF',
                fontSize: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {selectedproperty?.created_by}
              {selectedproperty?.is_verified && (
                <img
                  src={SUCCESSTICK}
                  alt="profile"
                  className="agent-verified-icon"
                  style={{ zIndex: 1 }}
                />
              )}
            </Typography>
          </Box>
                    </Box>
                    <Typography
        sx={{
          display: 'flex',
          marginRight: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#FEFEFE',
          fontSize: '12px',
        }}
      >
        Made with the Magic of
        <img src={vREFYD_WHITE} width={60} alt="vREfyd" />
      </Typography>

        </Box>

        </Box>
        
    </Box>
  );
};

export default Flyer8;
