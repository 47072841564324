import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from '../Utils/translations';
import apis from '../Services/api-services';
import { userActions } from '../Store/userSlice';
import { useDispatch } from 'react-redux';
import { loginActions } from '../Store/loginSlice';
import { LOGIN } from '../constants/route-path';

const ConfirmDeleteAccountModal = ({ open, onClose }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [confirmText, setConfirmText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleConfirmTextChange = (e) => {
    setConfirmText(e.target.value);
    if (error) setError('');
  };

  const handleDeleteAccount = async () => {
    if (confirmText !== 'DELETE') {
      setError(
        t(TRANSLATIONS?.ERRORS?.CONFIRM_DELETE_TEXT) ||
          'Please type DELETE to confirm'
      );
      return;
    }

    try {
      setLoading(true);

      const data = await apis.user.deleteAccount();

      if (data) {
        dispatch(userActions.clearUserData());
        dispatch(loginActions.loggedOut());
        localStorage.clear();
        window.location.replace(LOGIN);
      }
    } catch (error) {
      setError(
        error?.response?.data?.message ||
          'Failed to delete account. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          backgroundColor: '#f8f8f8',
          color: '#d32f2f',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <DeleteIcon color="error" />
        {t(TRANSLATIONS?.TITLES?.DELETE_ACCOUNT) || 'Delete Account'}
      </DialogTitle>

      <DialogContent sx={{ py: 3 }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {t(TRANSLATIONS?.MESSAGES?.DELETE_ACCOUNT_WARNING) ||
            'Warning: This action cannot be undone. All your data, including properties, messages, and profile information will be permanently deleted.'}
        </Typography>

        <Typography variant="body1" sx={{ mb: 3 }}>
          {t(TRANSLATIONS?.MESSAGES?.DELETE_ACCOUNT_CONFIRM) ||
            'To confirm deletion, please type DELETE in the field below.'}
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <TextField
          fullWidth
          variant="outlined"
          value={confirmText}
          onChange={handleConfirmTextChange}
          placeholder={
            t(TRANSLATIONS?.PLACEHOLDERS?.TYPE_DELETE) ||
            'Type DELETE to confirm'
          }
          sx={{ mb: 2 }}
        />

        <Box
          sx={{
            backgroundColor: '#fff8f8',
            p: 2,
            borderRadius: 1,
            border: '1px solid #ffcccc',
          }}
        >
          <Typography variant="body2" color="error">
            {t(TRANSLATIONS?.MESSAGES?.DELETE_ACCOUNT_NOTICE) ||
              'By deleting your account, you acknowledge that you will lose access to all services and data associated with this account.'}
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button onClick={onClose} variant="outlined" disabled={loading}>
          {t(TRANSLATIONS?.BUTTONS?.CANCEL) || 'Cancel'}
        </Button>
        <Button
          onClick={handleDeleteAccount}
          variant="contained"
          color="error"
          startIcon={
            loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <DeleteIcon />
            )
          }
          disabled={loading || confirmText !== 'DELETE'}
        >
          {loading
            ? t(TRANSLATIONS?.BUTTONS?.DELETING) || 'Deleting...'
            : t(TRANSLATIONS?.BUTTONS?.DELETE_ACCOUNT) || 'Delete Account'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteAccountModal;
