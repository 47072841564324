import apis from '../Services/api-services';
import { requestFcmToken } from '../firebaseUtils';

export const colorsEnums = {
  error: '#A03C42',
  primary: '#755FFE',
};

export const formatMessage = (text) => {
  if (text) {
    const formattedText = text
      .replace(/^####\s(.+)$/gm, '<h4>$1</h4>') // Format level 4 headings
      .replace(/^###\s(.+)$/gm, '<h3>$1</h3>') // Format level 3 headings
      .replace(/^##\s(.+)$/gm, '<h2>$1</h2>') // Format level 2 headings
      .replace(/^#\s(.+)$/gm, '<h1>$1</h1>') // Format level 1 headings
      .replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>') // Bold text
      .replace(/^-\s(.+)$/gm, '<li>$1</li>') // Bullet points
      .replace(/\n/g, '<br />'); // Line breaks
    return formattedText;
  }
};

export const handleProfileRedirection = async (navigate) => {
  const { data: { profile } = {} } =
    await apis.agent.getAgentProfileCompletionStatus();

  if (!profile) return;

  const { verification_status } = profile;

  if (
    verification_status === 'Initiated' ||
    verification_status === 'Re-Initiated' ||
    verification_status === 'Verified'
  ) {
    navigate('/profile');
  } else {
    navigate('/profile-onboarding');
  }
};

export const phoneRegExp = /^[6-9]\d{9}$/;

export const fcmTokenUpdate = async (mobileNumber) => {
  await requestFcmToken().then(async (token) => {
    if (token) {
      await apis.notification.userNotificationFcmTokenUpdate(
        mobileNumber,
        token
      );
    }
  });
};
