import { Avatar, Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { calculateAge, numDifferentiation } from '../../../Utils/helperUtils';
import vREFYD_WHITE from '../../../Assets/images/vREfyd_logo.png';

const Flyer2 = ({ selectedproperty, coverImage }) => {
  return (
    <Box
      id="flyer1"
      sx={{
        width: '480px',
        height: '601px',
        margin: '10px',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${coverImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          filter: 'blur(2px)',
          zIndex: 1,
        },
      }}
    >
      <Typography
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          display: 'flex',
          marginRight: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#FEFEFE',
          zIndex: 9,
          fontSize: '12px',
        }}
      >
        Made with the Magic of
        <img src={vREFYD_WHITE} width={55} alt="vREfyd" />
      </Typography>

      <Box
        sx={{
          content: '""',
          position: 'absolute',
          zIndex: 2,
          top: 0,
          left: 0,
          width: '100%',
          height: '20%',
          background:
            'linear-gradient(180deg, #0A0A0A 0%, rgba(142, 142, 142, 0) 100%)',
        }}
      ></Box>
      <Box
        sx={{
          width: '80%',
          height: '80%',
          background: '#FFFFFF',
          borderRadius: '28px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
          position: 'relative',
          zIndex: 9,
          p: 2,
        }}
      >
        <Typography sx={{ color: '#1E1E1E' }}>
          {selectedproperty?.bedroom_count
            ? `${selectedproperty?.bedroom_count}BHK`
            : ''}{' '}
          {selectedproperty?.furnish_type} {selectedproperty?.property_type}{' '}
          {selectedproperty?.property_sale_type === 'Purchase'
            ? 'For Sale'
            : 'For Rent'}{' '}
          @{' '}
        </Typography>
        <Typography sx={{ fontWeight: 600, my: 2, fontSize: '30px' }}>
          {selectedproperty?.sale_price
            ? numDifferentiation(selectedproperty?.sale_price)
            : numDifferentiation(selectedproperty?.rent_amount)}
        </Typography>
        <Divider sx={{ my: '15px' }} />
        <Box sx={{ height: '60%', width: '100%' }}>
          <img
            src={coverImage}
            alt="img"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Box>
        <Typography
          sx={{
            width: '100%',
            fontSize: '16px',
            textAlign: 'center',
            my: 2,
            color: '#474747',
          }}
        >
          {selectedproperty?.locality}
        </Typography>
      </Box>
    </Box>
  );
};

export default Flyer2;
