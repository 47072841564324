import React, { useState } from 'react';
import {
  Dialog,
  Typography,
  Box,
} from '@mui/material';
import MOBILEHOME from '../Assets/images/mobile-home.png';
import { handleProfileRedirection } from '../constants/enums';
import { useNavigate } from 'react-router-dom';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const VerifyProfileModal = ({ open, onClose }) => {

  const navigate = useNavigate();


  

  return (
    <Dialog open={open} sx={{
        '& .MuiPaper-root':{
            borderRadius:'30px',
            maxWidth:'650px'
        }
    }} onClose={onClose} maxWidth="sm" fullWidth >
        <Box sx={{position:'absolute',right:'20px',top:'10px',cursor:'pointer'}} onClick={()=>onClose()}>
        <CloseOutlinedIcon sx={{color:'#747474',opacity:'0.7'}} />
        </Box>
        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',padding:'25px',paddingTop:'45px',paddingBottom:'0px',borderRadius:'10px'}}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { lg: 'column', xs: 'column' },
          alignItems: { lg: 'flex-start', xs: 'center' },
          justifyContent: 'flex-start',
          background: '#FFFFFF',
          paddingBottom:'20px'
        }}
      >
 <Typography
          sx={{
            width: { lg: '80%', xs: '100%' },
            fontSize: '16px',
            fontWeight: '600',
            color: '#590DB4',
          }}
        >
          Verify Your Profile to Add Properties
        </Typography>

        <Typography
          sx={{
            width: { lg: '90%', xs: '100%' },
            fontSize: '16px',
            fontWeight: '300',
            marginY:'15px',
            color: '#7D7D7D',
          }}
        >
          Start the verification process to begin adding properties right away.
        </Typography>
        <Box onClick={() => {
            handleProfileRedirection(navigate)
            onClose()
            }} sx={{cursor:'pointer',fontSize:'12px',display:'flex',flexDirection:'row',alignItems:'center',textWrap:'nowrap',background:'#590DB4',color:'#fff',padding:'5px 10px',borderRadius:'100px'}}>
        Verify Now
          <Box
            sx={{
              width: '23px',
              height: '23px',
              borderRadius: '50%',
              background: '#ffffff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
              ,marginLeft:'5px' 
            }}
            color="primary"
            aria-label="add"
          >
            <ArrowForwardOutlinedIcon sx={{ width: '18px', color: '#590DB4'}} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          paddingBottom: 0,
        }}
      >
        <img width={182} src={MOBILEHOME} alt="mobile-picture" />
      </Box>
      </Box>
    </Dialog>
  );
};

export default VerifyProfileModal;
