import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import vREFYD_WHITE from '../../../Assets/images/vREfyd_logo.png';
import SUCCESSTICK from '../../../Assets/images/success-tick.png';
import { numDifferentiation } from '../../../Utils/helperUtils';

const Flyer3 = ({ selectedproperty, coverImage }) => {
  return (
    <Box
      id="flyer1"
      sx={{
        width: '480px',
        height: '601px',
        margin: '10px',
        backgroundImage: `url(${coverImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          background: '#0000008b',
          height: '88%',
          width: '80%',
          position: 'absolute',
          borderRadius: '12px',

          top: '50%',
          right: '50%',
          transform: 'translate(50%,-50%)',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '10px',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'row', marginLeft: '15px' }}
          >
            <Avatar
              src={selectedproperty?.profile_picture}
              sx={{
                width: '35px',
                height: '35px',
                objectFit: 'cover',
                zIndex: 0,
              }}
            />
            <Box
              sx={{
                marginLeft: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
              }}
            >
              <Typography sx={{ color: '#FEFEFE', fontSize: '10px' }}>
                Listed by
              </Typography>
              <Typography
                sx={{
                  color: '#FFFFFF',
                  fontSize: '12px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {selectedproperty?.created_by}
                {selectedproperty?.is_verified && (
                  <img
                    src={SUCCESSTICK}
                    alt="profile"
                    className="agent-verified-icon"
                    style={{ zIndex: 1 }}
                  />
                )}
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{
              display: 'flex',
              marginRight: '10px',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#FEFEFE',
              fontSize: '12px',
            }}
          >
            Made with the Magic of
            <img src={vREFYD_WHITE} width={60} alt="vREfyd" />
          </Typography>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            right: '50%',
            transform: 'translate(50%,-50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              fontSize: '26px',
              textAlign: 'center',
              width: '100%',
              color: '#eaeaea',
            }}
          >
            {selectedproperty?.furnish_type}
          </Typography>
          <Typography
            sx={{
              fontSize: '26px',
              textAlign: 'center',
              width: '100%',
              color: '#eaeaea',
            }}
          >
            {selectedproperty?.property_type}
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              textAlign: 'center',
              width: '100%',
              color: '#eaeaea',
            }}
          >
            {selectedproperty?.property_sale_type === 'Purchase'
              ? 'For Sale'
              : 'For Rent'}{' '}
            @{' '}
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              textAlign: 'center',
              width: '100%',
              color: '#eaeaea',
            }}
          >
            {selectedproperty?.sale_price
              ? numDifferentiation(selectedproperty?.sale_price)
              : numDifferentiation(selectedproperty?.rent_amount)}
          </Typography>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '10px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{ width: '100%', textAlign: 'center', color: '#ffffff' }}
          >
            {selectedproperty?.property_type}{' '}
            {selectedproperty?.bedroom_count
              ? `with ${selectedproperty?.bedroom_count} Bedrooms`
              : ''}{' '}
          </Typography>
          <Typography
            sx={{ width: '100%', textAlign: 'center', color: '#ffffff' }}
          >
            {selectedproperty?.locality}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Flyer3;
