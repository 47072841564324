import { Avatar, Box, Divider, Typography } from '@mui/material';
import React from 'react';
import vREFYD_WHITE from '../../../Assets/images/vREfyd_logo.png';
import SUCCESSTICK from '../../../Assets/images/success-tick.png';
import vREfydHOME from '../../../Assets/images/vREfyd-small-icon.png';

const Flyer7 = ({ selectedproperty, coverImage }) => {
  return (
    <Box
      id="flyer1"
      sx={{
        width: '480px',
        height: '601px',
        margin: '10px',
        backgroundImage: `url(${coverImage})`, 
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
        backgroundRepeat: 'no-repeat',
        position: 'relative', 
        overflow: 'hidden',
      }}
    >
        <Box sx={{
            top:0,
            width:'100%',
            background:'#0000008b',
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            padding:'13px 10px'

        }}>
                <Box sx={{display:'flex',flexDirection:'row',marginLeft:'15px'}}>
                    <Avatar
            src={selectedproperty?.profile_picture}
            sx={{
              width: '35px',
              height: '35px',
              objectFit: 'cover',
              zIndex: 0,
            }}
          />
          <Box
            sx={{
              marginLeft: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <Typography sx={{ color: '#FEFEFE', fontSize: '10px' }}>
              Listed by
            </Typography>
            <Typography
              sx={{
                color: '#FFFFFF',
                fontSize: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {selectedproperty?.created_by}
              {selectedproperty?.is_verified && (
                <img
                  src={SUCCESSTICK}
                  alt="profile"
                  className="agent-verified-icon"
                  style={{ zIndex: 1 }}
                />
              )}
            </Typography>
          </Box>
                    </Box>
                    <Typography
        sx={{
          display: 'flex',
          marginRight: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#FEFEFE',
          fontSize: '12px',
        }}
      >
        Made with the Magic of
        <img src={vREFYD_WHITE} width={60} alt="vREfyd" />
      </Typography>

        </Box>
        <Box sx={{
            position:'absolute',
            bottom:0,
            width:'100%',
            padding:'30px 40px',
            background:
            'linear-gradient(0deg, rgba(10,10,10,1) 0%, rgba(142,142,142,0) 100%)',
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'flex-start',

        }}>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center'
            }}>
            <Box sx={{height:'55px',background:'#fff',width:'2px'}}>{" "}</Box>
            <img src={vREfydHOME} width={15} style={{margin:' 15px 10px'}} alt='vrefyd' />
            <Box sx={{height:'55px',background:'#fff',width:'2px'}}>{" "}</Box>
            </Box>
            <Box sx={{
                marginLeft:'8px',

            }}>
                <Typography sx={{
                    color:'#fff',
                    fontSize:'15px'
                }}>
                {selectedproperty?.property_sale_type === 'Purchase'
            ? 'FOR SALE'
            : 'FOR RENT'}{': '}
                </Typography>
                <Typography sx={{color:'#fff',fontSize:'18px',fontWeight:700}}>Luxury {selectedproperty?.property_type} {" "} {selectedproperty?.bathroom_count ? `${selectedproperty?.bathroom_count} Bedrooms`:'' }</Typography>
           
            </Box>
           


        </Box>
        
    </Box>
  );
};

export default Flyer7;
