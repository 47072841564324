import React, { cloneElement } from 'react';
import vREFYD_WHITE from '../Assets/images/vREfyd_logo.png';
import vREFYD_SMALLICON from '../Assets/images/vREfyd-small-icon.png';
import './loginWrapper.css';
import AlertMessage from './alertmessage';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from '../Utils/translations';

const LoginWrapperNew = (props) => {
  const { openSnackbar, setOpenSnackbar, setAlertmsg, alertType, alertMsg } =
    props;

  const { t } = useTranslation();

  return (
    <Box className="login-container">
      <Box
        sx={{
          position: 'absolute',
          bottom: '55px',
          left: '55px',
        }}
      >
        <Typography
          sx={{
            color: '#FFFFFF',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '40px',
          }}
        >
          {t(TRANSLATIONS.SUB_TITLES.NOT_JUST_PART_OF_SYSTEM)}
        </Typography>
        <Typography
          sx={{
            color: '#FFFFFF',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '40px',
            marginY: '3px',
          }}
        >
          {' '}
          {t(TRANSLATIONS.SUB_TITLES.YOU_ARE_THE_SYSTEM)}
        </Typography>
        <Typography
          sx={{
            color: '#FFFFFF',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '22px',
            marginY: '10px',
          }}
        >
          {t(TRANSLATIONS.SUB_TITLES.JOIN_COMMUNITY)}
        </Typography>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: '55px',
          left: '55px',
          display: {
            xs: 'none',
            xmd: 'block',
          },
        }}
      >
        <img
          src={vREFYD_WHITE}
          alt="vREfyd-logo"
          width={130}
          style={{ marginBottom: '40px' }}
        />
      </Box>

      <LoginSection {...props} />

      <AlertMessage
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        setAlertmsg={setAlertmsg}
        alertType={alertType}
        alertMsg={alertMsg}
      />
    </Box>
  );
};

const LoginSection = (props) => {
  return (
    <Box
      sx={{
        position: 'relative',
        margin: '12px',
        borderRadius: '20px',
        width: {
          xs: '100%',
          xmd: '450px',
        },
        height: '98vh',
        background: '#FFFFFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '50px',
            height: '50px',
            background: '#600DB4',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
            marginLeft: '43px',
          }}
        >
          <img
            src={vREFYD_SMALLICON}
            alt="vREfyd-logo"
            style={{ width: '25px' }}
          />
        </Box>

        <Box
          sx={{
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '15px',
            borderRadius: '20px',
            minHeight: '290px',
            position: 'relative',
            marginLeft: '10px',
          }}
        >
          {cloneElement(props.children, { ...props })}
        </Box>
      </Box>
    </Box>
  );
};

export default LoginWrapperNew;
