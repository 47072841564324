import requestServer from '../request-server';

let internals = {};

internals.getTeamMembers = function (data) {
  return requestServer(
    'v1/organisation/members',
    data,
    'GET',
    {},
    'application/json',
    true
  );
};

internals.removeTeamMember = function (data) {
  return requestServer(
    'v1/organisation/members',
    data,
    'DELETE',
    {},
    'application/json',
    true
  );
};

internals.addTeamMember = function (data) {
  return requestServer(
    'v1/organisation/members',
    data,
    'POST',
    {},
    'application/json',
    true
  );
};

internals.updateTeamMember = function (data) {
  return requestServer(
    'v1/organisation/members',
    data,
    'PATCH',
    {},
    'application/json',
    true
  );
};

internals.getOrganisations = function (data) {
  return requestServer(
    'v1/user/organisations',
    data,
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getMemberDetails = function (memberId) {
  return requestServer(
    `v1/organisation/member/${memberId}/details`,
    {},
    'GET',
    {},
    'application/json',
    true
  );
};

internals.memberLogin = function (data) {
  return requestServer(
    'v1/member/send_otp',
    data,
    'POST',
    {},
    'application/json',
    false
  );
};

internals.verifyMemberOtp = function (data) {
  return requestServer(
    'v1/member/verify_otp',
    data,
    'POST',
    {},
    'application/json',
    false
  );
};

internals.authorizeUser = function ({ organisationId, phoneNumber }) {
  return requestServer(
    `v1/organisation/${organisationId}/user/${phoneNumber}/authorize`,
    {},
    'POST',
    {},
    'application/json',
    false
  );
};

export default internals;
