




import { Avatar, Box, Divider, Typography } from '@mui/material';
import React from 'react';
import vREFYD_WHITE from '../../../Assets/images/vREfyd_logo.png';
import { numDifferentiation } from '../../../Utils/helperUtils';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';

const Flyer5 = ({ selectedproperty, coverImage }) => {
  return (
   
 <Box
         id="flyer1"
         sx={{
             width: '480px',
             height: '601px',
             margin: '10px',
            background:'#000',
             position: 'relative',
             overflow: 'hidden',
         }}
     >
         <Divider
             sx={{
                 position: "absolute",
                 top: '20px',
                 width: '100%',
                 borderBottomWidth: '2px',
                 backgroundColor: 'white'
             }}
         />
 
         <Box
             sx={{
                 position: "absolute",
                 top: '40px',
                 left: '0',
                 width: '100%',
                 height: '55%',
                 backgroundImage: `url(${coverImage})`,
                 backgroundSize: 'cover',
                 backgroundPosition: 'center',
                 backgroundRepeat: 'no-repeat',
             }}
         />
 
         <Box
             sx={{
                 position: "absolute",
                 bottom: '0',
                 left: '0',
                 width: '100%',
                 height: '51%',
                 fontFamily: 'Times New Roman, serif',
                 display: 'flex',
                 flexDirection: 'column',
                 justifyContent: 'center',
                 alignItems: 'center',
                 color: '#FFFFFF',
             }}
         >
             <Box sx={{ mt: '80px' }}>
                 <Box sx={{ fontSize: '30px', mt: '10px'}}>
                    Classy {selectedproperty?.property_type}
                 </Box>
 
                 <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:'center', marginTop: '15px', fontSize: '24px' }}>
                <Typography sx={{fontSize:'20px'}}>
                 {selectedproperty?.sale_price
            ? numDifferentiation(selectedproperty?.sale_price)
            : numDifferentiation(selectedproperty?.rent_amount)}
            {" "} 
        </Typography>
        {selectedproperty?.property_sale_type === 'Rent' && (
          <Typography
            sx={{ color: '#fff', fontWeight: 500, fontSize: '20px',marginLeft:'5px' }}
          >
           {" "} / Month
          </Typography>)}
                 </Box>
             </Box>
 
             <Box
                 sx={{
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     gap: '70px',
                     marginTop: '15px',
                     marginBottom: '5px',
                 }}
             >
                <Box>
                 <Box
                     sx={{
                         width: '60px',
                         height: '55px',
                         borderRadius: '50%',
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center',
                         border: '2px solid white',
                     }}
                 >
                <SquareFootOutlinedIcon />       
                  </Box>
                  <Typography sx={{fontSize:'12px',textAlign:'center',mt:1}}>{selectedproperty?.built_up_area} Sq.Ft</Typography>
                 </Box>
 
 <Box>

                 <Box
                     sx={{
                         width: '60px',
                         height: '55px',
                         borderRadius: '50%',
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center',
                         border: '2px solid white',
                     }}
                 >
                     <BathtubOutlinedIcon />
                 </Box>
                 <Typography sx={{fontSize:'12px',textAlign:'center',mt:1}}>{selectedproperty?.bathroom_count} Bathroom</Typography>
                 </Box>

                     <Box>
                 <Box
                     sx={{
                         width: '60px',
                         height: '55px',
                         borderRadius: '50%',
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center',
                         border: '2px solid white',
                     }}
                 >
                        <BedOutlinedIcon />
                 </Box>
                 <Typography sx={{fontSize:'12px',textAlign:'center',mt:1}}>{selectedproperty?.bedroom_count} Bedroom</Typography>
                 </Box>
             </Box>
 
             {/* Divider with vrefydsymbol and vrefyd in the center */}
             <Box
                 sx={{
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                     width: '100%',
                     marginTop: '5px',
                     position: 'relative',
                 }}
             >
                 {/* Left Divider */}
                 <Divider sx={{ flex: 1, borderBottomWidth: '2px', backgroundColor: 'white' }} />
 
                 {/* vrefydsymbol and vrefyd with some gap */}
                 <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px', px: '35px' }}>
                   <img src={vREFYD_WHITE} alt='vrefyd' style={{width:'70px'}} />
               </Box>
 
                 {/* Right Divider */}
                 <Divider sx={{ flex: 1, borderBottomWidth: '2px', backgroundColor: 'white' }} />
             </Box>
         </Box>
     </Box>
  );
};

export default Flyer5;
