import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { excludeIds } from '../constants/app-constants';

const PropertyImageGallery = ({
  images = [],
  thumbnailLimit = 5,
  initialIndex = 0,
  handleSetCoverImage = null,
  coverImage,
  fromListing = false,
}) => {
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  if (!images.length) {
    return <Typography>No media available</Typography>;
  }

  // Open Fullscreen
  const handleOpen = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  // Close Fullscreen
  const handleClose = () => setOpen(false);

  // Go to Next Media
  const handleNext = () => {
    if (currentIndex < images?.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Go to Previous Media
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Render Media (Image or Video)
  const renderMedia = (item, style, onClick) => {
    if (item?.file_type?.startsWith('video')) {
      return (
        <video
          src={item.file_url}
          style={style}
          controls
          onClick={onClick}
          muted
        />
      );
    }
    return (
      <img src={item.file_url} alt="Media" style={style} onClick={onClick} />
    );
  };

  return (
    <>
      {/* Main Media */}
      {images[0] &&
        renderMedia(
          images[0],
          {
            borderRadius: '8px',
            cursor: 'pointer',
            height: '100%',
            width: '100%',
            marginBottom: '10px',
          },
          () => handleOpen(0)
        )}

      {/* Thumbnails Outside Modal */}
      <Grid
        container
        sx={{ gap: 1, overflow: 'scroll', flexWrap: 'nowrap', width: '100%' }}
      >
        {images?.slice(1, thumbnailLimit).map((item, index) => (
          <Grid item lg={2.85} key={index} sx={{ maxHeight: '6rem' }}>
            {renderMedia(
              item,
              {
                borderRadius: '4px',
                cursor: 'pointer',
                height: '100%',
                objectFit: 'cover',
                width: '100%',
              },
              () => handleOpen(index + 1)
            )}
          </Grid>
        ))}
      </Grid>

      {/* Fullscreen Modal */}
      <Dialog open={open} onClose={handleClose} fullScreen>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: '94%',
            justifyContent: 'center',
            position: 'relative',
            width: '100%',
            backgroundColor: '#fff',
          }}
        >
          {/* Main Media in Fullscreen */}
          {images[currentIndex]?.file_type?.startsWith('video') ? (
            <video
              src={images[currentIndex].file_url}
              style={{
                height: '90%',
                objectFit: 'contain',
                width: '90%',
              }}
              controls
              autoPlay
            />
          ) : (
            <img
              src={images[currentIndex].file_url}
              alt="Fullscreen"
              style={{
                height: '90%',
                objectFit: 'contain',
                width: '90%',
              }}
            />
          )}

          {/* Set Cover Image Option */}
          {fromListing &&
            handleSetCoverImage &&
            images[currentIndex]?.file_type?.startsWith('image') &&
            !excludeIds.includes(images[currentIndex]?.file_id) && (
              <Box
                onClick={() =>
                  coverImage !== images[currentIndex]?.file_id &&
                  handleSetCoverImage(images[currentIndex]?.file_id)
                }
                sx={{
                  position: 'absolute',
                  top: '50px',
                  right: '10px',
                  background: '#0000005E',
                  color: '#ffffff',
                  cursor: 'pointer',
                  pr: 1,
                }}
              >
                <Checkbox
                  sx={{ color: '#FFFFFF' }}
                  checked={coverImage === images[currentIndex]?.file_id}
                  color="default"
                />
                {` ${
                  coverImage !== images[currentIndex]?.file_id ? 'Set as' : ''
                } Cover image`}
              </Box>
            )}
        </Box>

        {/* Scrollable Thumbnails Inside Modal */}
        <Box
          sx={{
            display: 'flex',
            overflowX: 'auto',
            mt: 2,
            p: 1,
            bgcolor: 'transparent',
            position: 'absolute',
            bottom: 10,
            left: '50%',
            transform: 'translateX(-50%)',
            justifyContent:'center',
            width: '80%',
            borderRadius: '8px',
            '&::-webkit-scrollbar': { height: '8px' },
            '&::-webkit-scrollbar-thumb': {
              background: '#888',
              borderRadius: '8px',
            },
          }}
        >
          {images.map((item, index) => (
            <Box
              key={index}
              onClick={() => setCurrentIndex(index)}
              sx={{
                minWidth: '70px',
                height: '60px',
                marginRight: '10px',
                cursor: 'pointer',
                borderRadius: '8px',
                overflow: 'hidden',
                border:
                  currentIndex === index
                    ? '2px solid #1976D2'
                    : '1px solid #ccc',
              }}
            >
              {item?.file_type?.startsWith('video') ? (
                <video
                  src={item.file_url}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                  muted
                />
              ) : (
                <img
                  src={item.file_url}
                  alt="thumbnail"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              )}
            </Box>
          ))}
        </Box>

        {/* Close Button */}
        <DialogActions sx={{ position: 'absolute', right: 0, top: 0 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: '#000' }} />
          </IconButton>
        </DialogActions>

        {/* Previous Button */}
        <DialogActions sx={{ bottom: '50%', left: '2%', position: 'absolute' }}>
          <IconButton onClick={handlePrevious} disabled={currentIndex === 0}>
            <ArrowBackIosIcon sx={{ color: '#000' }} />
          </IconButton>
        </DialogActions>

        {/* Next Button */}
        <DialogActions
          sx={{ bottom: '50%', position: 'absolute', right: '2%' }}
        >
          <IconButton
            onClick={handleNext}
            disabled={currentIndex === images?.length - 1}
          >
            <ArrowForwardIosIcon sx={{ color: '#000' }} />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PropertyImageGallery;
