import requestServer from '../request-server';

let internals = {};

internals.getUserData = function (data) {
  return requestServer('v1/users', data, 'GET', {}, 'application/json', true);
};

internals.sendOtp = function (data) {
  return requestServer(
    'v1/member/send_otp',
    data,
    'POST',
    {},
    'application/json',
    false
  );
};

internals.updateUser = function (data) {
  return requestServer(
    'v1/user/update',
    data,
    'PATCH',
    {},
    'application/json',
    true
  );
};

internals.verifyOtp = function (data) {
  return requestServer(
    'v1/member/verify_otp',
    data,
    'POST',
    {
      'Grant-Type': 'Bearer',
    },
    'application/json',
    false
  );
};

internals.getRefreshToken = function (data) {
  return requestServer(
    'v1/users/refresh_token',
    data,
    'POST',
    {
      'Grant-Type': 'refresh_token',
    },
    'application/json',
    false,
    true
  );
};

internals.resendOtp = function (data) {
  return requestServer(
    'aira/api/resend-otp',
    data,
    'POST',
    {},
    'application/json',
    false
  );
};

internals.deleteAccount = function (data) {
  return requestServer(
    'v1/user/delete/account',
    data,
    'DELETE',
    {},
    'application/json',
    true
  );
};

export default internals;
