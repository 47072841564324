import UserApis from './APIs/user-apis';
import chatApis from './APIs/chat-apis';
import dropdownApis from './APIs/dropdown-apis';
import propertyApis from './APIs/property-apis';
import agentApis from './APIs/agent-apis';
import unLockChatsAPIs from './APIs/unlockChat-apis';
import notificationAPIs from './APIs/notification-apis';
import organisationAPIs from './APIs/organisation-apis';

const apis = {
  user: UserApis,
  chat: chatApis,
  dropdown: dropdownApis,
  property: propertyApis,
  agent: agentApis,
  unlockchats: unLockChatsAPIs,
  notification: notificationAPIs,
  organisation: organisationAPIs,
};

export default apis;
